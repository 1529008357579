import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout.js'



export default ({ data}) => {
  const writing = data.markdownRemark;
  return (
      <article className='writing-piece'>
      <header>
        <h1>{writing.frontmatter.title}</h1>
      </header>
          <div dangerouslySetInnerHTML={{__html: writing.html}} className="words"/>
        <Layout></Layout>
      </article>
     
  );
}

export const query = graphql`
query($slug: String!) {
  markdownRemark(fields: {slug: {eq: $slug}}) {
    html
    frontmatter {
      title
    }
  }
}
`
